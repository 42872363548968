import { DELETE, GET, PATCH, POST, PUT } from 'src/utils/request';
import { CATALOG_SERVICE_API_PREFIX } from 'src/consts/third_party';
import { MONOLITH_API_PREFIX, CLIENT_ID } from 'src/consts';

// 获取附加选项详情接口地址
export function getQueryCustomizationDetailPath(customizationId) {
  return `${CATALOG_SERVICE_API_PREFIX}/api/v1/customized_properties/${customizationId}`;
}
// 搜索附加选项接口地址
export const searchCustomizationsPath = `${CATALOG_SERVICE_API_PREFIX}/api/v1/customized_properties/search`;

export async function getAllMenuChannels() {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/channels`);
}

/**
 * 绑定菜单去掉
 * @param {Object} params
 * @param {string} params.restaurant_id 餐厅id
 * @param {string} params.menu_id 菜单id
 * @param {string[]} params.channel_names 渠道名数组
 * @returns
 */
export async function bindMenuChannels({ restaurant_id, menu_id, channel_names }) {
  return PATCH(`${CATALOG_SERVICE_API_PREFIX}/api/v1/menus/${menu_id}/bind_channel`, {
    restaurant_id,
    menu_id,
    channel_names,
  });
}

/**
 * 获取附加选项分页数据
 * @param {Object} params
 * @param {String} params.restaurant_id 餐厅id
 * @param {Number} params.page 页码
 * @param {Number} params.page_size 每页显示条数
 * @param {Boolean} params.include_detail 是否包含详情
 */
export async function queryCustomizationsPageData({
  restaurant_id,
  page = 1,
  page_size = 100,
  include_detail = false,
}) {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/customized_properties`, {
    restaurant_id,
    page,
    page_size,
    include_detail,
  });
}

/**
 * 获取附加选项详情
 * @param {String} customizationId 附加选项id
 */
export async function queryCustomizationDetail(customizationId) {
  return GET(getQueryCustomizationDetailPath(customizationId));
}

/**
 * 附加选项排序
 * @param {String} restaurantId 餐厅id
 * @param {SortCustomizedPropertyPayload|SortCustomizedOptionPayload} payload 排序payload
 * @type SortItem = { id: number, position: number }
 * @type SortCustomizedPropertyPayload = { customized_property: SortItem[] } 附加选项排序payload
 * @type SortCustomizedOptionPayload = { customized_option: SortItem[] } 附加选项子项排序payload
 */
export async function sortCustomizations(restaurantId, payload) {
  return PUT(`${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurantId}/customizations/sort`, payload, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
  });
}

/**
 * 附加选项搜索接口
 * @param {Object} params
 * @param {String} params.search_term 搜索关键字
 * @param {String} params.ids 需要查询的附加选项的ids, 用逗号隔开
 */
export async function searchCustomizations({ search_term, ids }) {
  return GET(searchCustomizationsPath, { search_term, ids });
}

/**
 * 更新附加选项
 * @param {Object} params
 * @param {String} params.restaurant_id 餐厅id
 * @param {String} params.customized_property_id 附加选项id
 * @param {Object} params.payload 附加选项属性
 */
export async function updateCustomizedProperty({ restaurant_id, customized_property_id, payload }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurant_id}/customizations/${customized_property_id}`,
    payload,
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}

/**
 * 创建附加选项
 * @param {Object} params
 * @param {String} params.restaurant_id 餐厅id
 * @param {Object} params.payload 附加选项属性
 */
export async function createCustomizedProperty({ restaurant_id, payload }) {
  return POST(`${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurant_id}/customizations`, payload, {
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
    },
  });
}

/**
 * 批量绑定/移除附加选项到菜品
 * @param {Object} params
 * @param {String} param.restaurant_id 餐厅id
 * @param {String[]} params.customized_option_ids 附加选项id
 * @param {String[]} params.meal_instance_ids_added 批量绑定的菜品ids
 * @param {String[]} params.meal_instance_ids_removed 批量移除绑定的菜品ids
 */
export async function batchAssignCustomizationsToMeals({
  restaurant_id,
  customized_option_ids,
  meal_instance_ids_added,
  meal_instance_ids_removed,
}) {
  return POST(
    `${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurant_id}/customizations/bind`,
    {
      customized_option_ids,
      meal_instance_ids_added,
      meal_instance_ids_removed,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}

/**
 * 删除附加选项
 * @param {string} restaurant_id 餐厅id
 * @param {string} customization_id 附加选项id
 */
export async function deleteCustomization(restaurant_id, customization_id) {
  return DELETE(
    `${MONOLITH_API_PREFIX}/api/restaurant/v3/restaurants/${restaurant_id}/customizations/${customization_id}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
    }
  );
}
