import { MENU_BUILDER_DISTRIBUTION_MODES } from 'src/components/MenuManagement/MenuBuilder/const';
import { loadAllMenusOfRestaurant } from 'src/helpers/meal';
import { getAllMenuChannels } from 'src/services/catalog';
import { sortMenuCategories, sortPOSMenusSequence, updateCategoryDisplayColor } from 'src/services/meal';
import { getThirdPartySettings } from 'src/services/settings';
import { USER_KEYS, getSessionItem } from 'src/store/storage';
import { DELIVERY_PLATFORM_STATUS_ENUMS } from 'src/consts/enum';
import { getPlatformForeignName } from 'src/components/MenuManagement/MenuBuilder/utils';

const BASE = 'MENU_BUILDER';

export const actions = {
  loadingMenuTree: `${BASE}_LOADING_MENU_TREE`,
  loadMenuTreeSuccess: `${BASE}_LOAD_EMNU_TREE_SUCCESS`,
  loadMenuTreeFailed: `${BASE}_LOAD_EMNU_TREE_FAILED`,
  loadingMenuChannels: `${BASE}_LOADING_MENU_CHANNELS`,
  loadMenuChannelsDone: `${BASE}_LOAD_MENU_CHANNELS_DONE`,
  sortingMenus: `${BASE}_SORTING_MENUS`,
  sortMenusSuccess: `${BASE}_SORT_MENUS_SUCCESS`,
  sortMenusFailed: `${BASE}_SORT_MENUS_FAILED`,
  sortingCategories: `${BASE}_SORTING_CATEGORIES`,
  sortCategoriesSuccess: `${BASE}_SORT_CATEGORIES_SUCCESS`,
  sortCategoriesFailed: `${BASE}_SORT_CATEGORIES_FAILED`,
  menuCacheChanged: `${BASE}_MENU_CACHE_CHANGED`,
  categoryCacheChanged: `${BASE}_CATEGORY_CACHE_CHANGED`,
  updateMenuPathBeforeSearch: `${BASE}_UPDATE_MENU_PATH_BEFORE_SEARCH`,
  loadingDeliveryChannels: `${BASE}_LOADING_DELIVERY_CHANNELS`,
  loadDeliveryChannelsSuccess: `${BASE}_LOAD_DELIVERY_CHANNELS_SUCCESS`,
  loadDeliveryChannelsFailed: `${BASE}_LOAD_DELIVERY_CHANNELS_FAILED`,
};

export const fetchMenuTree = () => async (dispatch) => {
  const restaurantId = getSessionItem(USER_KEYS.restaurantId);

  dispatch({ type: actions.loadingMenuTree });
  const response = await loadAllMenusOfRestaurant(restaurantId);
  if (!response.success) {
    dispatch({
      type: actions.loadMenuTreeFailed,
    });
    return;
  }

  const responseData = response.data;
  const menusGroupedByContract = {};
  const menuCacheMap = {};
  const categoryCacheMap = {};
  responseData.forEach((menu) => {
    const { restaurant_contract, categories, ...other } = menu;
    if (!categories.length) return;
    const { distribution_mode } = restaurant_contract;
    let contractCache = menusGroupedByContract[distribution_mode];
    if (!contractCache) {
      contractCache = {
        ...restaurant_contract,
        menu_ids: [],
        sequence: MENU_BUILDER_DISTRIBUTION_MODES.indexOf(distribution_mode),
      };
      menusGroupedByContract[distribution_mode] = contractCache;
    }
    const menuId = String(menu.id);
    contractCache.menu_ids.push(menuId);
    const categoryIds = [];
    categories.forEach((category) => {
      const categoryId = String(category.id);
      const { stat, display_color, ...other } = category;
      if (!categoryCacheMap[categoryId]) {
        categoryCacheMap[categoryId] = {
          ...other,
          menu_id: menuId,
          distribution_mode,
          displayColorCache: {},
          statCache: {},
        };
      }
      categoryCacheMap[categoryId].displayColorCache[menuId] = display_color;
      categoryCacheMap[categoryId].statCache[menuId] = stat || {};
      categoryIds.push(categoryId);
    });
    menuCacheMap[menuId] = {
      ...other,
      restaurant_contract,
      id: menuId,
      category_ids: categoryIds,
      distribution_mode,
    };
  });
  dispatch({
    type: actions.loadMenuTreeSuccess,
    payload: {
      menusGroupedByContract,
      menuCacheMap,
      categoryCacheMap,
    },
  });
};

export const sortMenusWithinContract =
  ({ distributionMode, menuIds, callback }) =>
  async (dispatch, getState) => {
    dispatch({ type: actions.sortingMenus });

    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await sortPOSMenusSequence(restaurantId, menuIds);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({ type: actions.sortMenusFailed });
      return;
    }

    const { menusGroupedByContract } = getState().menuBuilder;
    if (!menusGroupedByContract) return;

    const clonedContractMap = { ...menusGroupedByContract };

    const menusOfContract = clonedContractMap[distributionMode];
    if (menusOfContract) {
      menusOfContract.menu_ids = menuIds;
    }

    dispatch({
      type: actions.sortMenusSuccess,
      payload: clonedContractMap,
    });

    typeof callback === 'function' && callback();
  };

export const sortCategoryWithinMenu =
  ({ menuId, categoryIds, callback }) =>
  async (dispatch, getState) => {
    dispatch({ type: actions.sortingCategories });

    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await sortMenuCategories({ restaurantId, menuId, categoryIds });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({ type: actions.sortCategoriesFailed });
      return;
    }

    const { menuCacheMap } = getState().menuBuilder;
    const clonedMenuCacheMap = { ...menuCacheMap };
    const menu = clonedMenuCacheMap[menuId];
    if (menu) {
      clonedMenuCacheMap[menuId] = {
        ...menu,
        category_ids: categoryIds,
      };
    }
    dispatch({
      type: actions.sortCategoriesSuccess,
      payload: clonedMenuCacheMap,
    });

    typeof callback === 'function' && callback();
  };

export const removeCategoryWithinMenu =
  ({ menuId, categoryId }) =>
  async (dispatch, getState) => {
    const { menuCacheMap } = getState().menuBuilder;
    const newMenuCacheMap = { ...menuCacheMap };
    const menu = newMenuCacheMap[menuId];
    if (!menu) return;
    const categoryIds = [...menu.category_ids];
    const index = categoryIds.indexOf(categoryId);
    if (index < 0) return;
    categoryIds.splice(index, 1);
    menu.category_ids = categoryIds;
    dispatch({
      type: actions.menuCacheChanged,
      payload: newMenuCacheMap,
    });
  };

export const changeCategoryColorWithinMenu =
  ({ menu_id, category_id, display_color }, callback) =>
  async (dispatch, getState) => {
    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;

    try {
      response = await updateCategoryDisplayColor({
        restaurant_id: restaurantId,
        menu_id,
        category_id,
        display_color,
      });
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) return;

    const { categoryCacheMap } = getState().menuBuilder;
    const _category = categoryCacheMap[category_id];

    if (!_category) {
      if (typeof callback === 'function') callback();
      return;
    }

    const newCategoryCacheMap = { ...categoryCacheMap };
    const category = newCategoryCacheMap[category_id];
    category.displayColorCache[menu_id] = display_color;
    dispatch({
      type: actions.categoryCacheChanged,
      payload: newCategoryCacheMap,
    });
    if (typeof callback === 'function') callback();
  };

export const cacheMenuPathBeforeSearch = (menuPath) => async (dispatch, getState) => {
  const { menuPathBeforeSearch } = getState().menuBuilder;
  if (menuPathBeforeSearch) return;

  dispatch({
    type: actions.updateMenuPathBeforeSearch,
    payload: menuPath,
  });
};

export const clearMenuPathBeforeSearch = () => async (dispatch) => {
  dispatch({
    type: actions.updateMenuPathBeforeSearch,
    payload: undefined,
  });
};

export const fetchAllMenuChannels = () => async (dispatch, getState) => {
  const { isLoadedMenuChannels } = getState().menuBuilder;
  if (isLoadedMenuChannels) return;

  dispatch({ type: actions.loadingMenuChannels });
  let response;

  try {
    response = await getAllMenuChannels();
  } catch (e) {
    response = { success: false };
  }

  dispatch({
    type: actions.loadMenuChannelsDone,
    payload: {
      success: response.success,
      data: response.success ? response.data || [] : [],
    },
  });
};

/***
 * 获取Delivery channels
 * @param {Boolean} needRefresh 是否需要刷新
 */
export const fetchDeliveryChannels =
  (needRefresh = false) =>
  async (dispatch, getState) => {
    const { isLoadDeliveryChannelsSuccess } = getState().menuBuilder;
    if (!needRefresh && isLoadDeliveryChannelsSuccess) return;

    dispatch({ type: actions.loadingDeliveryChannels });
    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    let response;
    try {
      response = await getThirdPartySettings(restaurantId);
    } catch (e) {
      response = { success: false };
    }

    if (!response.success) {
      dispatch({ type: actions.loadDeliveryChannelsFailed });
      return;
    }

    const deliveryChannels = [];
    const platformsOfMenus = {};
    const { channels } = response.data || {};
    if (channels && channels.length) {
      channels.forEach((channel) => {
        if (channel.status === DELIVERY_PLATFORM_STATUS_ENUMS.ACTIVE) {
          const { menus, ...other } = channel;
          other.foreign_name = getPlatformForeignName(channel.name);
          other.menu_ids = [];
          deliveryChannels.push(other);
          (menus || []).forEach(({ menu_id: id }) => {
            other.menu_ids.push(String(id));
            platformsOfMenus[id] = platformsOfMenus[id] || [];
            platformsOfMenus[id].push(channel.name);
          });
        }
      });
    }
    dispatch({
      type: actions.loadDeliveryChannelsSuccess,
      payload: {
        deliveryChannels,
        platformsOfMenus,
      },
    });
  };

/**
 * 刷新菜单缓存
 * @param {Object} newMenuInfo
 */
export const updateMenuCache = (newMenuInfo) => async (dispatch, getState) => {
  const { menuCacheMap } = getState().menuBuilder;
  const newMenuCacheMap = { ...menuCacheMap };
  const oldMenuInfo = newMenuCacheMap[newMenuInfo.id];
  if (oldMenuInfo) {
    newMenuCacheMap[newMenuInfo.id] = {
      ...oldMenuInfo,
      ...newMenuInfo,
    };
  } else {
    menuCacheMap[newMenuInfo.id] = newMenuInfo;
  }
  dispatch({
    type: actions.menuCacheChanged,
    payload: newMenuCacheMap,
  });
};
